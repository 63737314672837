<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="group_sms">
        <b-row>
          <b-col md="4">
            <b-card-text>
              <span>Select Role</span>
            </b-card-text>
            <validation-provider
              #default="{ errors }"
              name="Role"
              rules="required"
            >
              <select
                name="role_id"
                v-model="role_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
              >
                <option :value="null">Select One</option>
                <option
                  v-for="(role, key) in roles"
                  :value="role.id"
                  :key="key"
                >
                  {{ role.name }}
                </option>
                <option :value="1000">Admission Student</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <template v-if="role_id === 4">
            <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                name="class"
                rules="required"
              >
                <select
                  name="classes_id"
                  v-model="classes_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control"
                >
                  <option :value="null">Select One</option>
                  <option
                    v-for="(cls, key) in classes"
                    :value="cls.id"
                    :key="key"
                  >
                    {{ cls.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Version</span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                name="version"
                rules="required"
              >
                <select
                  name="version_id"
                  v-model="version_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control"
                >
                  <option :value="null">Select One</option>
                  <option
                    v-for="(version, key) in versions"
                    :value="version.id"
                    :key="key"
                  >
                    {{ version.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Shift</span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                name="Shift"
                rules="required"
              >
                <select
                  name="shift_id"
                  v-model="shift_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control"
                >
                  <option :value="null">Select One</option>
                  <option
                    v-for="(shift, key) in shifts"
                    :value="shift.id"
                    :key="key"
                  >
                    {{ shift.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Student Group</span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                name="student group"
                rules="required"
              >
                <select
                  name="student_group_id"
                  v-model="student_group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control"
                >
                  <option :value="null">Select One</option>
                  <option
                    v-for="(sgrp, key) in student_groups"
                    :value="sgrp.id"
                    :key="key"
                  >
                    {{ sgrp.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </template>

          <b-col md="10">
            <b-card-text>
              <span>Message Body </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Message text"
                rules="required"
              >
                <b-form-textarea
                  rows="5"
                  v-model="msg_text"
                  name="msg_text"
                  placeholder="Message Text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormTextarea,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  //BFormRadioGroup,
} from "bootstrap-vue";
// import { required, integer } from "@validations";
import apiCall from "@/libs/axios";
import { mapGetters } from "vuex";
export default {
  name: "subjectAdd",
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    //BFormRadioGroup,
  },
  data() {
    return {
      msg_type: 1,
      msg_options: [
        { text: "En", value: 1 },
        { text: "Bn", value: 0 },
      ],
      msg_text: "",
      student_group_id: null,
      version_id: null,
      shift_id: null,
      classes_id: null,
      role_id: null,
    };
  },
  created() {
    this.$store.dispatch("GET_ALL_ROLE");
    this.$store.dispatch("GET_ALL_CLASSES");
    this.$store.dispatch("GET_ALL_VERSION");
    this.$store.dispatch("GET_ALL_SHIFT");
    this.$store.dispatch("GET_ALL_STUDENT_GROUP");
  },
  methods: {
    async validationForm() {
      await this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let data = new FormData(this.$refs.group_sms);
          apiCall
            .post("/group/sms/store", data)
            .then((response) => {
              if (response.data.status === "success") {
                this.$toaster.success(response.data.message);
                this.$refs.group_sms.reset();
                this.$refs.simpleRules.reset();
              } else {
                this.$toaster.error(response.data.message);
              }
            })
            .catch((error) => {
              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                });
              } else this.$toaster.error(error.response.data.message);
            });
        }
      });
    },
  },
  computed: {
    ...mapGetters(["roles", "classes", "versions", "shifts", "student_groups"]),
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>